import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => {

  const image = 'https://rfallp.com/images/assets/images/GettyImages-457980607.jpg';

  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Services | Estate and Gift Planning"
          image={image}
          slug={location.pathname}
          description="Our experienced professionals will assist you in implementing and developing a successful succession plan for your family’s wealth."
        />
        {/* Main */}
        <section className="wrapper style1">
          <div className="container">
            <div className="row gtr-200">
              <div className="col-4 col-12-narrower">
                <div id="sidebar">

                  {/* Sidebar */}

                  <section>
                    <h3>Services</h3>
                    <ul className="links">
                      <li><Link to="/services/">Overview</Link></li>
                      <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                      <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                      <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                      <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                      <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                    </ul>
                  </section>

                </div>
              </div>
              <div className="col-8  col-12-narrower imp-narrower">
                <div id="content">

                  {/* Content */}
                  <article>
                    <header>
                      <h2>Estate and Gift Planning</h2>
                      <p>
                        Our experienced professionals will assist you in implementing and developing a successful succession plan for your family’s wealth.
                      </p>
                    </header>

                    <span className="image featured">
                      <StaticImage
                        src="../../assets/images/GettyImages-457980607.jpg"
                        alt=""
                        width={725}
                      />
                    </span>

                    <p>
                    Rosewood Family Advisors has considerable experience in helping families create a legacy for their wealth. This may involve providing for and protecting family members, supporting causes and charitable organizations that are important to the family, and developing a structure to maintain family values and vision across future generations.
                    </p>
                    <p>
                    You can rely on us to:
                    </p>
                    <ul>
                      <li>Perform a detailed review and diagnostic analysis of your current estate and wealth transfer plan.</li>
                      <li>Identify the most tax-efficient and beneficial entity structures to accomplish your goals.</li>
                      <li>Fully integrate the wealth transfer plan with your family’s tax, investment, business and insurance planning.</li>
                      <li>Implement and administer gift and estate planning structures such as GRATs, Family Limited Partnerships and trusts to ensure that they conform to their respective formalities and intended purposes.</li>
                      <li>Make repairs to well-intended plans and strategies that were not properly executed or administered, or that need adjustment because of changes in law or personal circumstances.</li>
                      <li>Undertake objective reviews of existing life insurance coverage and assess the overall need for life insurance without any bias that might result from sales commissions or  product offerings.</li>
                      <li>Work collaboratively with outside legal counsel to modify or enhance existing documents, or to implement new planning strategies with the goal of ensuring that all documents accurately reflect your wishes.</li>
                    </ul>
                    <p>
                    Rosewood Family Advisors has estate planning professionals on staff with decades of experience who can advise in these highly technical areas. We do not provide legal advice or draft legal documents. Rather, we collaborate with your legal advisors, review documents carefully and provide feedback and suggestions to ensure that the documents are consistent with your overall goals.
                    </p>

                  </article>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InteriorPage;
